import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Header from './header'

async function loginUser(credentials) {
    return fetch('https://clinica-djacira.herokuapp.com/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
}

export default function Login({setToken}) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        let token = await loginUser({
            username,
            password
        });
        if(token.token==="Usuário não encontrado."){
            alert("Usuário não encontrado.");
        }else if(token.token==="Senha incorreta."){
            alert("Senha incorreta.")
        }else{
            setToken(token);
        }
    }

    return (
        <div>
            <Header />
            <form onSubmit={handleSubmit} id="hero">
                <div className="d-flex justify-content-center align-items-center">
                    <div className="form-group" style={{"marginTop": "200px","zIndex":"1000"}}>
                        <h2>Acesso ao sistema interno<span>.</span></h2>
                        <div className="row mt-2">
                            <input className="form-control text-center" type="text" id="login" name="login" autoComplete='off' placeholder="usuário" required onChange={e => setUserName(e.target.value)} />
                        </div>
                        <div className="row mt-2">
                            <input className="form-control text-center" type="password" id="password" name="password" autoComplete='off' placeholder="senha" required onChange={e => setPassword(e.target.value)} />
                        </div>
                        <div className="row mt-2">
                            <input className="btn btn-warning" type="submit" value="Log In" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};
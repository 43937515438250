import React, { Component } from 'react'
import axios from "axios";
import HeaderApp from "./headerApp"



class Pendencias extends Component {
    state = {
        h1: 'Carregando pendências...',
        arrayClients: []
    }

    componentDidMount() {
        axios.post('https://clinica-djacira.herokuapp.com/api/findmanypend')
            .then(foundTests => {
                for (var i = 0; i < foundTests.data.length; ++i) {
                    this.setState({
                        arrayClients: this.state.arrayClients.concat(
                            {
                                _id: foundTests.data[i]._id,
                                CPF: foundTests.data[i].CPF.substr(0, 3) + "." + foundTests.data[i].CPF.substr(3, 3) + "." + foundTests.data[i].CPF.substr(6, 3) + "-" + foundTests.data[i].CPF.substr(9, 2),
                                Name: foundTests.data[i].Name,
                                TestDate: String(foundTests.data[i].TestDate).substr(8, 2) + "/" + String(foundTests.data[i].TestDate).substr(5, 2) + "/" + String(foundTests.data[i].TestDate).substr(0, 4),
                                PendObs: foundTests.data[i].PendObs,
                                Cidade: foundTests.data[i].TestCity
                            }
                        )
                    })
                };

                if(foundTests.data.length === 0){
                    this.setState({h1:'Nenhuma pendência cadastrada.'})
                }else{
                    this.setState({h1:'Pendências'})
                }
            })   
    }

    createClientList = (item) => {
        return(
            <div className="row mt-2">
                <hr/>
                <div className="col-md-5">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-8">
                            <label className="mt-2 mb-2">Nome:</label>
                            <input className="form-control text-center" type='text' value={item.Name} disabled/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4">
                            <label className="mt-2 mb-2">CPF:</label>
                            <input className="form-control text-center" type='text' value={item.CPF} disabled/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <label className="mt-2 mb-2">Cidade da Avaliação:</label>
                            <input className="form-control text-center" type='text' value={item.Cidade} disabled/>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <label className="mt-2 mb-2">Data da Avaliação:</label>
                            <input className="form-control text-center mb-3" type='text' value={item.TestDate} disabled/>
                        </div>
                    </div>
                </div>

                <div className="col-md-7">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-9">
                            <label className="mt-2 mb-2">Pendências:</label>
                            {item.PendObs.map(this.createPendList)}
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-3 my-auto">
                            <button id={item._id} className='btn btn-danger' onClick={this.handleBtnExcluir}>Excluir pendências</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createPendList = (item) => {
        const random_key = Math.random()
        return(
                <li key={random_key}>{item}</li>            
        )
    }

    handleBtnExcluir = async (event) => {
        await axios.post('https://clinica-djacira.herokuapp.com/api/updatepend',{'_id':event.target.id})
        window.location.reload()
    }

    render() { 
        var listPend = this.state.arrayClients.map(this.createClientList)
        return (
            <div className="form-group container flex text-center" style={{paddingTop: 90}}>
                <div className="row mt-3">
                    <h1>{this.state.h1}</h1>
                </div>
                
                <HeaderApp />
                {listPend}
            </div>
            
        );
    }
}
 
export default Pendencias;
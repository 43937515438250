import React, { Component } from 'react'

class Hero extends Component {
    render() { 
        return (
            <section id="hero" className="d-flex align-items-center justify-content-center">
                <div className="container" data-aos="fade-up">
                    <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                        <div className="col-xl">
                            <h1>Psicóloga credenciada à Polícia Federal<span>.</span></h1>
                            <h2>Avaliações Psicológicas para manuseio de arma de fogo em Parnamirim e Mossoró<span>.</span></h2>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Hero;
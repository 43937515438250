import React from 'react'
import RegistrarAvaliacao from './regaval'
import AtualizarAvaliacao from './attaval'
import GerarLaudo from './gerlaud'
import RegDescritivo from './laudodescritivo'
import Pendencias from './pendcheck'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import Home from './home'
import Login from './login'
import useToken from './useToken'


function MyApp() {
    
    const { token, setToken } = useToken();

    return (
        <React.Fragment>
            
            <Router>
                <Switch>
                    <Route exact path="/" component={Home} />
                    {/* <Route exact path="/app" component={PrivateApp} /> */}
                    {/* <Route exact path='/AtualizarAvaliacao' component={RegistrarAvaliacao} /> */}

                    { token &&
                        <Route exact path='/RegistrarAvaliacao' component={RegistrarAvaliacao} />
                    }
                    { token &&
                        <Route exact path='/BuscarAtualizarAvaliacao' component={AtualizarAvaliacao} />
                    }
                    { token && 
                        <Route exact path='/Pendencias' component={Pendencias} />
                    }

                    { token && 
                        <Route exact path='/GerarLaudoConclusivo' component={GerarLaudo} />
                    }
                    { token && 
                        <Route exact path='/RegistrarLaudoDescritivo' component={RegDescritivo} />
                    }

                    { !token &&
                        <Login setToken={setToken} />
                    }
                </Switch>
            </Router>
            
        </React.Fragment>
    );
}
 
export default MyApp;
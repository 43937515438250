import React, { Component } from 'react'

class Footer extends Component {
    state = {  }
    render() { 
        return (
            <footer id="footer" className="footer py-2">
                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Djacira Dantas</span></strong>. All Rights Reserved
                    </div>
                    {/* <div class="credits">
                        Designed by <a>DjaciraDantas</a>
                    </div> */}
                </div>
            </footer>
            
        );
    }
}
 
export default Footer;
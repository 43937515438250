import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

class Contact extends Component {
    state = {  }
    render() { 
        return (
            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Fale Conosco</h2>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-around">
                            <div className="info">
                                <div className="email">
                                    <i><FontAwesomeIcon icon={faEnvelope} /></i>
                                    <h4>Email:</h4>
                                    <p><a href="mailto:djaciradantas.clinica@gmail.com" target="_blank" rel="noopener noreferrer">djaciradantas.clinica@gmail.com</a></p>
                                </div>
                                <div className="phone">
                                    <i><FontAwesomeIcon icon={faWhatsapp} /></i>
                                    <h4>WhatsApp:</h4>
                                    <p><a href="https://api.whatsapp.com/send?phone=5584999826652" target="_blank" rel="noopener noreferrer">Clique aqui para enviar mensagem.</a></p>
                                </div>
                                <div className="phone">
                                <i><FontAwesomeIcon icon={faMobileAlt} /></i>
                                    <h4>Telefones:</h4>
                                    <p><a href="tel:084999826652" target="_blank" rel="noopener noreferrer">Ligar para (84) 9.9982-6652</a></p>
                                    <p><a href="tel:084988113411" target="_blank" rel="noopener noreferrer">Ligar para (84) 9.8811-3411</a></p>
                                    <p><a href="tel:08431131387" target="_blank" rel="noopener noreferrer">Ligar para (84) 3113-1387 (Apenas Parnamirim)</a></p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-8 mt-5 mt-lg-0">
                            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <input type="text" name="name" className="form-control" id="name" placeholder="Seu Nome" required />
                                    </div>
                                    <div className="col-md-6 form-group mt-3 mt-md-0">
                                        <input type="email" className="form-control" name="email" id="email" placeholder="Seu E-mail" required />
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Assunto" required />
                                </div>
                                <div className="form-group mt-3">
                                    <textarea className="form-control" name="message" rows="5" placeholder="Messagem" required></textarea>
                                </div>
                                <div className="my-3">
                                    <div className="loading">Carregando</div>
                                    <div className="error-message"></div>
                                    <div className="sent-message">Sua mensagem foi enviada. Retornaremos o contato em breve!</div>
                                </div>
                                <div className="text-center"><button type="submit">Enviar Mensagem</button></div>
                            </form>
                        </div> */}
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Contact;
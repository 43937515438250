import React, { Component } from 'react'
import HeaderApp from './headerApp'
import axios from 'axios'
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";

const validarCpf = require('validar-cpf');

async function findOne(findId) {
    return axios.post('https://clinica-djacira.herokuapp.com/api/findoneid', findId)
}

async function testesRequest() {
    // https://clinica-djacira.herokuapp.com/
    return axios.get('https://clinica-djacira.herokuapp.com/api/testesrequest')
}

async function regDescritivo(newDescritivo) {
    return axios.post('http://localhost:3001/api/regdescritivo', newDescritivo)
}

class RegDescritivo extends Component {
    state = {
        _id:'',
        cpf:'',
        testDate:Date(),
        name:'',
        testCity:'',
        foundTestes:[],
        allVariables:{},
        listTestes:[],
        listVariaveis:[],
        listVariaveisValues:[],
        laudoTrue: false,
        registrarTrue: false
    }

    async componentDidMount() {
        let foundTestes = await testesRequest();
        this.setState({foundTestes: foundTestes.data})
    }

    setID = (dateSelected) => {
        let day = dateSelected.split("-")[2];
        let month = dateSelected.split("-")[1];
        let year = dateSelected.split("-")[0];
        let cpf = this.state.cpf;

        if(day.toString().length===1){
            day = "0" +  day.toString();
        };
        if(month.toString().length===1){
            month = "0" + month.toString();
        };

        const id_test = cpf + day + month + year;
        if(id_test.length !== 19){
            return false
        }

        this.setState({_id:id_test});

        return id_test
    }

    handleSelecionarTestes = (event) => {
        event.preventDefault();

        var listTestes = []

        this.state.foundTestes.map((item) => {
            var checkBox = document.getElementById(item.Teste)
            if(checkBox.checked){
                listTestes.push(checkBox.id)
            }
        })

        this.setState({
            listTestes:listTestes,
            registrarTrue:true
        })
    }

    handleRegistrarLaudoDescritivo = async (imprimir,event) => {
        event.preventDefault();

        var listVariaveis = []
        var listVariaveisValues = []

        this.state.foundTestes.map((item) => {
            var Teste = item.Teste
            item.Variaveis.map((variavel) => {
                var variavel_el = document.getElementById(Teste+variavel)
                if(this.state.listTestes.includes(Teste)){
                    listVariaveis.push(variavel_el.id)
                    listVariaveisValues.push(variavel_el.value)
                }
            })
        })

        if(listVariaveisValues.includes('')){
            alert('Há campos em branco. Preencha todas as variáveis.')
            return
        }else{
            this.setState({
                listVariaveis:listVariaveis,
                listVariaveisValues:listVariaveisValues
            })
            
            var mes_str = ''
            if(this.state.laudoDate.substr(5, 2)==='01'){
                mes_str = 'Janeiro'
            }else if(this.state.laudoDate.substr(5, 2)==='02'){
                mes_str = 'Fevereiro'
            }else if(this.state.laudoDate.substr(5, 2)==='03'){
                mes_str = 'Março'
            }else if(this.state.laudoDate.substr(5, 2)==='04'){
                mes_str = 'Abril'
            }else if(this.state.laudoDate.substr(5, 2)==='05'){
                mes_str = 'Maio'
            }else if(this.state.laudoDate.substr(5, 2)==='06'){
                mes_str = 'Junho'
            }else if(this.state.laudoDate.substr(5, 2)==='07'){
                mes_str = 'Julho'
            }else if(this.state.laudoDate.substr(5, 2)==='08'){
                mes_str = 'Agosto'
            }else if(this.state.laudoDate.substr(5, 2)==='09'){
                mes_str = 'Setembro'
            }else if(this.state.laudoDate.substr(5, 2)==='10'){
                mes_str = 'Outubro'
            }else if(this.state.laudoDate.substr(5, 2)==='11'){
                mes_str = 'Novembro'
            }else if(this.state.laudoDate.substr(5, 2)==='12'){
                mes_str = 'Dezembro'
            }
            
            var localData = this.state.testCity + ', RN, ' + this.state.laudoDate.substr(8, 2) + " de " + mes_str + " de " + this.state.laudoDate.substr(0, 4) + '.'

            var testes_procedimentos_quantitativa = []
            this.state.foundTestes.map((teste) => {
                if(teste.Tipo !== 'Personalidade' & this.state.listTestes.includes(teste.Teste)){
                    testes_procedimentos_quantitativa = testes_procedimentos_quantitativa.concat(teste.Teste)
                }
            })

            var testes_editado = ''
            for (var i = 0; i < testes_procedimentos_quantitativa.length; ++i){
                if(i===0){
                    testes_editado = testes_procedimentos_quantitativa[i]
                }else if(i===testes_procedimentos_quantitativa.length-2){
                    testes_editado = testes_editado + ', ' + testes_procedimentos_quantitativa[i] + ' e '
                }else if(i===testes_procedimentos_quantitativa.length-1 & testes_procedimentos_quantitativa.length>2){
                    testes_editado = testes_editado + testes_procedimentos_quantitativa[i]
                }else if(i===testes_procedimentos_quantitativa.length-1 & testes_procedimentos_quantitativa.length===2){
                    testes_editado = testes_editado + ' e ' + testes_procedimentos_quantitativa[i]
                }else{
                    testes_editado = testes_editado + ', ' + testes_procedimentos_quantitativa[i]
                }
            }

            var personalidade_texto = ''
            var atencao_texto = ''
            var memoria_texto = ''
            var var_name = ''
            var teste_name = ''
            for (i = 0; i < this.state.listTestes.length; ++i){
                for (var j = 0; j < this.state.foundTestes.length; ++j){
                    if(this.state.listTestes[i] === this.state.foundTestes[j].Teste){
                        if(this.state.foundTestes[j].Tipo === 'Personalidade'){
                            personalidade_texto = personalidade_texto + this.state.foundTestes[j].Qualitativo + ' '
                        }else if(this.state.foundTestes[j].Tipo === 'Atenção'){
                            atencao_texto = atencao_texto + this.state.foundTestes[j].Qualitativo + ' '
                        }else if(this.state.foundTestes[j].Tipo === 'Memória'){
                            memoria_texto = memoria_texto + this.state.foundTestes[j].Qualitativo + ' '
                        }
                        break
                    }
                }
                for (var k = 0; k < listVariaveis.length; ++k){
                    var_name = listVariaveis[k].replace(this.state.listTestes[i],'')
                    teste_name = listVariaveis[k].replace(var_name,'')
                    if(teste_name === this.state.listTestes[i]){
                        var_name = '{' + var_name + '}'
                        if(this.state.foundTestes[j].Tipo === 'Personalidade'){
                            personalidade_texto = personalidade_texto.replace(var_name,listVariaveisValues[k])
                        }else if(this.state.foundTestes[j].Tipo === 'Atenção'){
                            atencao_texto = atencao_texto.replace(var_name,listVariaveisValues[k])
                        }else if(this.state.foundTestes[j].Tipo === 'Memória'){
                            memoria_texto = memoria_texto.replace(var_name,listVariaveisValues[k])
                        }
                    }
                }
            }

            var texto_editado = ''
            if(atencao_texto!==''){
                texto_editado = texto_editado + atencao_texto
            }
            if(memoria_texto!==''){
                texto_editado = texto_editado + memoria_texto
            }
            if(personalidade_texto!==''){
                texto_editado = texto_editado + personalidade_texto
            }

            var arrTablesData = []
            var classificacao = ''
            for (i = 0; i < testes_procedimentos_quantitativa.length; ++i){
                for (j = 0; j < listVariaveisValues.length; ++j){
                    if(listVariaveis[j] === testes_procedimentos_quantitativa[i]+'Classificação'){
                        classificacao = listVariaveisValues[j]
                        break
                    }
                }
                arrTablesData = arrTablesData.concat({
                    TESTNOME: testes_procedimentos_quantitativa[i],
                    I: classificacao==='I' ? 'X' : '',
                    MI: classificacao==='MI' ? 'X' : '',
                    M: classificacao==='M' ? 'X' : '',
                    MS: classificacao==='MS' ? 'X' : '',
                    S: classificacao==='S' ? 'X' : '',
                    MSS: classificacao==='MSS' ? 'X' : '',
                })
            }

            var arrLaudosData = [{
                NOME: this.state.name,
                TESTES: testes_editado,
                TEXTO: texto_editado,
                tables: arrTablesData,
                LOCALDATA: localData,
                PageBreak: '<w:p><w:r><w:br w:type="page"/></w:r></w:p>'
            }]

            console.log(texto_editado)

            // Registrar Laudo antes de imprimir
            var newDescritivo = {
                _id: this.state._id,
                Name: this.state.name,
                CPF: this.state.cpf,
                TestCity: this.state.testCity,
                TestDate: this.state.testDate,
                LaudoDate: this.state.laudoDate,
                TestsList: this.state.listTestes,
                VarNameList: listVariaveis,
                VarValuesList: listVariaveisValues,
                Tests: testes_editado,
                QuantiTable: arrTablesData,
                QualiTexto: texto_editado
            }

            let message = await regDescritivo(newDescritivo)

            if(message.data.message==='Laudo Descritivo registrado com sucesso.' & imprimir===1){
                arrLaudosData[arrLaudosData.length - 1].PageBreak = ''
                var filename_data = this.state.testDate.split('-')[2] + '-' + this.state.testDate.split('-')[1] + '-' + this.state.testDate.split('-')[0]
                this.generateDocument("https://clinicadjacira.s3.us-east-2.amazonaws.com/laudo_descritivo_react.docx", arrLaudosData, 'Laudo Descritivo_'+this.state.name+'_'+filename_data)
                alert('Laudo Descritivo Registrado e Impresso com sucesso.')
            }else if(message.data.message==='Laudo Descritivo registrado com sucesso.'){
                alert('Laudo Descritivo Registrado com sucesso.')
            }else{
                alert('Erro ao registrar laudo descritivo.')
            }
        }
    }

    handleBuscarAvaliacao = async (event) => {
        event.preventDefault();

        let cpfString = this.state.cpf;
        if(!validarCpf(cpfString)){
            alert("CPF não válido.")
            return
        }

        const dateSelected = this.state.testDate;
        const id_test = this.setID(dateSelected)
        if(id_test === false){
            alert("Erro ao criar ID.")
            return
        }

        const findId = {'_id': id_test}
        let testFound = await findOne(findId)

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        let today_str = yyyy + "-" + mm + "-" + dd

        this.setState({
            name: testFound.data[0].Name,
            testCity: testFound.data[0].TestCity,
            testDate: testFound.data[0].TestDate.substr(0,10),
            laudoDate: today_str,
            laudoTrue: true
        })

        document.getElementById("cpf").value = this.state.cpf
        document.getElementById("testDate").value = this.state.testDate
        document.getElementById("name").value = testFound.data[0].Name
        document.getElementById("testCity").value = testFound.data[0].TestCity
        document.getElementById("laudoDate").value = today_str
    }

    loadFile = (url, callback) => {
        PizZipUtils.getBinaryContent(url, callback);
    };

    generateDocument = (url, data, filename) => {
        this.loadFile(
          url,
          (error, content) => {
            if (error) {
              throw error;
            };
    
            var zip = new PizZip(content);
            var doc = new Docxtemplater(zip);
    
            doc.setData({
                "laudos": data,
            });
    
            try {
              // render the document (replace all occurrences of {first_name} by John, {last_name} by Doe, ...)
              doc.render();
            } catch (error) {
              console.log("error", error);
              throw error;
            }
    
            var out = doc.getZip().generate({
              type: "blob",
              mimeType:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            }); //Output the document using Data-URI
    
            saveAs(out, filename + ".docx");
          }
        );
    };

    createCheckTestes = (item) => {
        var Teste = item.Teste
        if(this.state.listTestes.includes(item.Teste)){
            return (
                <div className="container-flex">
                    <div className="row" style={{alignItems: 'center'}}>
                        
                        <div className="col-md-3">
                            <div className="form-check">
                                <label className="form-check-label">{item.Teste}</label>
                            </div>
                        </div>
                        
                        <div className="col-md-2">
                            <div className="form-group">
                                <label className="form-check-label">{item.Tipo}</label>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="row justify-content-md-center">
                                {item.Variaveis.map((Variavel,) => {
                                    return(
                                        <div className="col-md-auto mb-2">
                                            <input className="text-center" type='text' id={Teste+Variavel} placeholder={Variavel} required/>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <hr className="mt-3 mb-3" />
                    </div>
                </div>
            )
        }
    }

    render() { 

        return (
            <div className="form-group container flex text-center">
                
                <HeaderApp />

                { !this.state.laudoTrue ?
                    <div style={{paddingTop: 90}}>
                        {/* <div className="form-group container"> */}
                        <div className="form-group container-fluid flex text-center">
                            <div className="row mt-3">
                                <h1>Buscar Avaliação</h1>
                            </div>

                            <hr/>
                            
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                    <label className="mt-3 mb-3">CPF:</label>
                                    <input className="form-control text-center" type='tel' id='porcpfinput' name='cpf' required onChange={(event) => {this.setState({cpf:event.target.value.replace(/\D/g,'')})}} autoComplete='off' placeholder="Apenas números" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                    <label className="mt-3 mb-3">Data da Avaliação:</label>
                                    <input className="form-control text-center" type='date' id='testDateFrom' name='testDate' autoComplete='off' required onChange={(event) => {this.setState({testDate:event.target.value})}} />
                                </div>
                                <div className="mx-auto mt-3">
                                    <button className="btn btn-warning" onClick={this.handleBuscarAvaliacao} >Buscar Avaliação</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }

                { this.state.laudoTrue ?
                    <div style={{paddingTop: 90}}>
                        <div className="row mt-3">
                            <h1>Registrar Laudo Descritivo</h1>
                        </div>
                        
                        <hr/>

                        <div className="row justify-content-md-center mt-3">
                            <div className="col-md-auto">
                                <label>Interessado:</label><br/>
                                <input className="text-center" type='text' id='name' disabled/>
                            </div>
                            <div className="col-md-auto">
                                <label>CPF:</label><br/>
                                <input className="text-center" type='text' id='cpf' disabled/>
                            </div>
                            <div className="col-md-auto">
                                <label>Cidade da Avaliação:</label><br/>
                                <input className="text-center" type='text' id='testCity' disabled/>
                            </div>
                            <div className="col-md-auto">
                                <label>Data da Avaliação:</label><br/>
                                <input className="text-center" type='date' id='testDate' disabled/>
                            </div>
                            <div className="col-md-auto">
                                <label>Data do Laudo Descritivo:</label><br/>
                                <input className="text-center" type='date' id='laudoDate' onChange={(event) => {this.setState({laudoDate:event.target.value})}}/>
                            </div>
                        </div>

                        { this.state.laudoTrue & this.state.registrarTrue ?
                            <div>
                                <div className="row mt-5">
                                    <div className="col-md-3">
                                        <label>Teste:</label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>Tipo:</label>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Variáveis:</label>
                                    </div>
                                </div>

                                <hr/>

                                {this.state.foundTestes.map(this.createCheckTestes)}

                                <div className="container mt-3 mb-3">
                                    <button className="btn btn-warning" onClick={(event) => {this.handleRegistrarLaudoDescritivo(0,event)}}>Apenas Registrar Laudo Descritivo</button>
                                </div>

                                <div className="container mt-3 mb-3">
                                    <button className="btn btn-warning" onClick={(event) => {this.handleRegistrarLaudoDescritivo(1,event)}}>Registrar e Imprimir Laudo Descritivo</button>
                                </div>
                            </div> 
                            : null
                        }

                        { this.state.laudoTrue & !this.state.registrarTrue ?
                            <div className="container">
                                <div className="row mt-5">
                                    <label>Testes:</label>
                                </div>

                                <hr/>

                                {this.state.foundTestes.map((item) => {
                                    return (
                                        <div className="form-group">
                                            <input className="form-check-input" type="checkbox" id={item.Teste} name={item.Teste} value={item.Teste} />
                                            <label className="form-check-label" htmlFor={item.Teste}>{item.Teste}</label>
                                        </div>
                                    )
                                })}

                                <div className="container mt-3 mb-3">
                                    <button className="btn btn-warning" onClick={this.handleSelecionarTestes}>Selecionar Testes para Laudo Descritivo</button>
                                </div>
                            </div>
                            : null
                        }

                    </div>
                    : null
                }

            </div>
        );
    }
}
 
export default RegDescritivo
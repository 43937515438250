import React, { Component } from 'react'

class Units extends Component {
    state = {  }
    render() { 
        return (
            <section id="units" className="contact">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Nossas Unidades</h2>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h4 style={{textAlign:"center"}} >Parnamirim</h4>
                        </div>
                        <div className="col">
                            <h4 style={{textAlign:"center"}} >Mossoró</h4>
                        </div>
                        {/* <div className="col">
                            <h4 style={{textAlign:"center"}} >Pau dos Ferros</h4>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col">
                            <iframe title="Unidade Parnamirim" style={{border:"0", width: "100%", height: "270px"}} src={"https://maps.google.com/maps?q=Cl%C3%ADnica%20Psicol%C3%B3gica%20Djacira%20Dantas,%20Parnamirim,%20RN,%20Brasil&t=&z=15&ie=UTF8&iwloc=&output=embed"} frameBorder="0" allowFullScreen></iframe>
                        </div>
                        <div className="col">
                            <iframe title="Unidade Mossoró" style={{border:"0", width: "100%", height: "270px"}} src={"https://maps.google.com/maps?q=Cl%C3%ADnica%20Psicol%C3%B3gica%20Djacira%20Dantas,%20Mossor%C3%B3,%20RN,%20Brasil&t=&z=15&ie=UTF8&iwloc=&output=embed"} frameBorder="0" allowFullScreen></iframe>  
                        </div>
                        {/* <div className="col">
                            <iframe title="Unidade Pau dos Ferros" style={{border:"0", width: "100%", height: "270px"}} src={"https://maps.google.com/maps?q=Cl%C3%ADnica%20Psicol%C3%B3gica%20Djacira%20Dantas,%20Pau%20dos%20Ferros,%20RN,%20Brasil&t=&z=15&ie=UTF8&iwloc=&output=embed"} frameBorder="0" allowFullScreen></iframe>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col">
                            <p style={{textAlign:"center"}} >Av. Brg. Everaldo Breves, 241, Centro - Sala 303 - Edif. Emp. Olímpio</p>
                        </div>
                        <div className="col">
                            <p style={{textAlign:"center"}} >Rua José Damião, 294 – Santo Antônio (Rua do Hiper Bompreço)</p>
                        </div>
                        {/* <div className="col">
                            <p style={{textAlign:"center"}} >Rua Carloto Távora, 896 – São Benedito (Rua da Central do Cidadão)</p>
                        </div> */}
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Units;
import React, { Component } from 'react';
import axios from "axios";
import HeaderApp from "./headerApp"

const validarCpf = require('validar-cpf');

async function findOne(findId) {
    return axios.post('https://clinica-djacira.herokuapp.com/api/findoneid', findId)
}

async function updateTest(updateOneTest) {
    return axios.post('https://clinica-djacira.herokuapp.com/api/updateone', updateOneTest)
}

class AtualizarAvaliacao extends Component {
    state={
        _id:'',
        name:'',
        age:'',
        cpf:'',
        gender:'',
        status:'',
        education:'',
        profession:'',
        testDate: Date(),
        clientType:'',
        testValue:'',
        testCity:'',
        testResult:'',
        laudo:'',
        laudoDate: Date(),
        pendObs: [],
        pendObsTemp: '',
        address: '',
        phone: '',
        atualizarTrue: false
    }

    handleAtualizarAvaliacao = async (event) => {
        event.preventDefault();
        
        const updateOneTest = {
            _id:this.state._id,
            name:this.state.name,
            age:this.state.age,
            cpf:this.state.cpf,
            gender:this.state.gender,
            status:this.state.status,
            education:this.state.education,
            profession:this.state.profession,
            testDate:this.state.testDate,
            clientType:this.state.clientType,
            testValue:this.state.testValue,
            testCity:this.state.testCity,
            testResult:this.state.testResult,
            laudo:this.state.laudo,
            laudoDate:this.state.laudoDate,
            pendObs:this.state.pendObs,
            address: this.state.address,
            phone: this.state.phone
        }

        let updated = await updateTest(updateOneTest)

        if(updated.statusText === 'OK'){
            alert('Avaliação atualizada com sucesso.')
            this.setState({
                _id:'',
                name:'',
                age:'',
                cpf:'',
                gender:'',
                status:'',
                education:'',
                profession:'',
                testDate: Date(),
                clientType:'',
                testValue:'',
                testCity:'',
                testResult:'',
                laudo:'',
                laudoDate: Date(),
                pendObs:[],
                address:'',
                phone:'',
                atualizarTrue: false
            });
        }else{
            alert('Houve um erro ao atualizar a avaliação.')
        }

    }

    handleBuscarAvaliacao = async (event) => {
        event.preventDefault();

        let cpfString = this.state.cpf;

        if(!validarCpf(cpfString)){
            alert("CPF não válido.")
            return
        }

        const findId = {'_id': this.state._id}
        let testFound = await findOne(findId)

        let testResultTemp = ''
        if(testFound.data[0].Apto1 === '1'){
            testResultTemp = 'apto1'
        }else if(testFound.data[0].Apto2 === '1'){
            testResultTemp = 'apto2'
        }else if(testFound.data[0].Inapto === '1'){
            testResultTemp = 'inapto'
        }
        
        this.setState({
            name: testFound.data[0].Name,
            age: testFound.data[0].Age,
            gender: testFound.data[0].Gender,
            status: testFound.data[0].Status,
            education: testFound.data[0].Education,
            profession: testFound.data[0].Profession,
            clientType: testFound.data[0].ClientType,
            testValue: testFound.data[0].TestCost,
            testCity: testFound.data[0].TestCity,
            testResult: testResultTemp,
            laudo: testFound.data[0].Laudo,
            laudoDate: testFound.data[0].LaudoDate.substr(0,10),
            pendObs:testFound.data[0].PendObs ? testFound.data[0].PendObs : [],
            address: testFound.data[0].Address,
            phone: testFound.data[0].Phone,
            atualizarTrue: true,
        })

        document.getElementById("cpf").value = this.state.cpf
        document.getElementById("testDate").value = this.state.testDate
        document.getElementById("name").value = testFound.data[0].Name
        document.getElementById("age").value = testFound.data[0].Age
        document.getElementById("gender").value = testFound.data[0].Gender
        document.getElementById("status").value = testFound.data[0].Status
        document.getElementById("education").value = testFound.data[0].Education
        document.getElementById("profession").value = testFound.data[0].Profession
        document.getElementById("clientType").value = testFound.data[0].ClientType
        document.getElementById("testValue").value = testFound.data[0].TestCost
        document.getElementById("testCity").value = testFound.data[0].TestCity
        document.getElementById("laudo").value = testFound.data[0].Laudo
        document.getElementById("laudoDate").value = testFound.data[0].LaudoDate.substr(0,10)
        document.getElementById(testResultTemp).checked = true
        document.getElementById("address").value = testFound.data[0].Address
        document.getElementById("phone").value = testFound.data[0].Phone
    }

    setID = (dateSelected) => {
        let day = dateSelected.split("-")[2];
        let month = dateSelected.split("-")[1];
        let year = dateSelected.split("-")[0];
        let cpf = this.state.cpf;

        if(day.toString().length===1){
            day = "0" +  day.toString();
        };
        if(month.toString().length===1){
            month = "0" + month.toString();
        };

        const id_test = cpf + day + month + year;
        if(id_test.length !== 19){
            return false
        }

        this.setState({_id:id_test});

        return true
    }

    onChangeTestDate = (event) => {
        const dateSelected = event.target.value;
        this.setState({testDate:dateSelected});
        if(!this.setID(dateSelected)){
            alert("Erro ao criar ID.")
            return
        }
    }

    onChangeClientType = (event) => {
        this.setState({clientType:event.target.value});
        const clientType = event.target.value

        if(!clientType.includes("Particular")){
            this.setState({testValue:"130"})
            document.getElementById("testValue").value = "130"
        }else{
            this.setState({testValue:""})
            document.getElementById("testValue").value = ""
        }
    }

    onChangeTestValue = (event) => {
        this.setState({testValue:event.target.value});
        const testValue = event.target.value

        if(isNaN(testValue) || testValue.includes(".")){
            alert("No valor da avaliação é permitido apenas números. Não digite vírgula nem centavos e nem texto. Por exemplo, se a consulta foi R$ 130,00, digite apenas 130. Se a consulta foi gratuita, digite apenas 0.")
            document.getElementById("testValue").value = ""
        }
    }

    createPendList = (item) => {
        return <li key={Math.random()}>{item}</li>
    }

    render() { 
        
        var listPend = this.state.pendObs.map(this.createPendList)

        return (
            <div>
                <HeaderApp />
                { !this.state.atualizarTrue ?
                    <div style={{paddingTop: 90}}>
                        {/* <div className="form-group container"> */}
                        <div className="form-group container-fluid flex text-center">
                            <div className="row mt-3">
                                <h1>Buscar Avaliação</h1>
                            </div>

                            <hr/>
                            
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                    <label className="mt-3 mb-3">CPF:</label>
                                    <input className="form-control text-center" type='tel' id='porcpfinput' name='cpf' required onChange={(event) => {this.setState({cpf:event.target.value.replace(/\D/g,'')})}} autoComplete='off' placeholder="Apenas números" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                    <label className="mt-3 mb-3">Data da Avaliação:</label>
                                    <input className="form-control text-center" type='date' id='testDateFrom' name='testDate' autoComplete='off' required onChange={this.onChangeTestDate} />
                                </div>
                                <div className="mx-auto mt-3">
                                    <button className="btn btn-warning" onClick={this.handleBuscarAvaliacao} >Buscar Avaliação</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }

                { this.state.atualizarTrue ?
                    <div id="form-att" style={{paddingTop: 90}}>
                        <div className="form-group container text-center" style={{"width":"100%"}}>
                            <div className="row mt-3">
                                <h1>Atualizar Avaliação</h1>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6">
                                    <label className="mt-3 mb-3" htmlFor='name'>Nome Completo:</label>
                                    <input className="form-control text-center" type='text' id='name' name='name' required onChange={(event) => {this.setState({name:event.target.value})}} autoComplete='off' placeholder="Iniciais em maiúsculo"/>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-3">
                                    <label className="mt-3 mb-3" htmlFor='gender'>Sexo:</label>
                                    <select className="form-control text-center" id='gender' name='gender' required onChange={(event) => {this.setState({gender:event.target.value})}} >
                                        <option value='Masculino'>Masculino</option>
                                        <option value='Feminino'>Feminino</option>
                                    </select>
                                </div>
                                
                                <div className="col-xs-12 col-sm-12 col-md-3">
                                    <label className="mt-3 mb-3" htmlFor='status'>Estado Civil:</label>
                                    <select className="form-control text-center" id='status' name='status' required onChange={(event) => {this.setState({status:event.target.value})}}  >
                                        <option value='Solteiro(a)'>Solteiro(a)</option>
                                        <option value='União Estável'>União Estável</option>
                                        <option value='Separado(a)'>Separado(a)</option>
                                        <option value='Casado(a)'>Casado(a)</option>
                                        <option value='Divorciado(a)'>Divorciado(a)</option>
                                        <option value='Viúvo(a)'>Viúvo(a)</option>
                                    </select>
                                </div>
                            
                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <label className="mt-3 mb-3" htmlFor='education'>Escolaridade:</label>
                                    <select className="form-control text-center" id='education' name='education' required onChange={(event) => {this.setState({education:event.target.value})}} >
                                        <option value='Fundamental Incompleto'>Fundamental Incompleto</option>
                                        <option value='Fundamental Completo'>Fundamental Completo</option>
                                        <option value='Médio Incompleto'>Médio Incompleto</option>
                                        <option value='Médio Completo'>Médio Completo</option>
                                        <option value='Técnico Incompleto'>Técnico Incompleto</option>
                                        <option value='Técnico Completo'>Técnico Completo</option>
                                        <option value='Superior Incompleto'>Superior Incompleto</option>
                                        <option value='Superior Completo'>Superior Completo</option>
                                        <option value='Pós-graduação Incompleta'>Pós-graduação Incompleta</option>
                                        <option value='Pós-graduação Completa'>Pós-graduação Completa</option>
                                    </select>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <label className="mt-3 mb-3" htmlFor='age'>Idade:</label>
                                    <input className="form-control text-center" type='tel' id='age' name='age' required onChange={(event) => {this.setState({age:event.target.value})}} autoComplete='off' placeholder="Apenas números"/>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <label className="mt-3 mb-3" htmlFor='cpf'>CPF:</label>
                                    <input className="form-control text-center" type='tel' id='cpf' name='cpf' disabled autoComplete='off' placeholder="Apenas números"/>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <label className="mt-3 mb-3" htmlFor='profession'>Profissão:</label>
                                    <input className="form-control text-center" type='text' id='profession' name='profession' required onChange={(event) => {this.setState({profession:event.target.value})}} autoComplete='off' placeholder="Iniciais em maiúsculo"/>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <label className="mt-3 mb-3" htmlFor='clientType'>Tipo de Cliente:</label>
                                    <select className="form-control text-center" id='clientType' name='clientType' required onChange={this.onChangeClientType} >
                                        <option value='Particular - Arma'>Particular - Arma</option>
                                        <option value='Particular - Vigilante'>Particular - Vigilante</option>
                                        <option value='ADS'>ADS</option>
                                        <option value='AVLIS'>AVLIS</option>
                                        <option value='BRASIFORT'>BRASIFORT</option>
                                        <option value='CERTA'>CERTA</option>
                                        <option value={"CHAGA & ROCHA"}>{"CHAGA & ROCHA"}</option>
                                        <option value='DMB'>DMB</option>
                                        <option value='EMPRESSERV'>EMPRESSERV</option>
                                        <option value='EMVIPOL'>EMVIPOL</option>
                                        <option value='FEROLI'>FEROLI</option>
                                        <option value='FLASH'>FLASH</option>
                                        <option value='FORÇA ALERTA'>FORÇA ALERTA</option>
                                        <option value='GUARDA MUNICIPAL DE NATAL'>GUARDA MUNICIPAL DE NATAL</option>
                                        <option value='INTERFORT'>INTERFORT</option>
                                        <option value='ITAPETINGA'>ITAPETINGA</option>
                                        <option value='KAIRÓS'>KAIRÓS</option>
                                        <option value='MARSEG'>MARSEG</option>
                                        <option value='MASTER SEGURANCA'>MASTER SEGURANCA</option>
                                        <option value='MATA FRESCA'>MATA FRESCA</option>
                                        <option value='MEGAFORTES'>MEGAFORTES</option>
                                        <option value='MONTEFORTE'>MONTEFORTE</option>
                                        <option value='NATAL VIGILÂNCIA'>NATAL VIGILÂNCIA</option>
                                        <option value='NEUTRON'>NEUTRON</option>
                                        <option value='NE SEGURANCA'>NE SEGURANCA</option>
                                        <option value='PROSEGUR'>PROSEGUR</option>
                                        <option value='PROTEG'>PROTEG</option>
                                        <option value='RN SEGURANCA'>RN SEGURANCA</option>
                                        <option value='ROLAND'>ROLAND</option>
                                        <option value='SEGURPRO'>SEGURPRO</option>
                                        <option value='SERVIMOVEL'>SERVIMOVEL</option>
                                        <option value='SUPREMAX'>SUPREMAX</option>
                                        <option value='TKS SEGURANÇA'>TKS SEGURANÇA</option>
                                        <option value='USIBRAS'>USIBRAS</option>
                                        <option value='VITAMAIS'>VITAMAIS</option>
                                        <option value='--- OUTRA EMPRESA ---'>--- OUTRA EMPRESA ---</option>
                                    </select>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <label className="mt-3 mb-3" htmlFor='testDate'>Data da Avaliação:</label>
                                    <input className="form-control text-center" type='date' id='testDate' name='testDate' autoComplete='off' disabled />
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <label className="mt-3 mb-3" htmlFor='testCity'>Cidade da Avaliação:</label>
                                    <select className="form-control text-center" id='testCity' name='testCity' required onChange={(event) => {this.setState({testCity:event.target.value})}} >
                                        <option value='Parnamirim'>Parnamirim</option>
                                        <option value='Mossoró'>Mossoró</option>
                                        <option value='Pau dos Ferros'>Pau dos Ferros</option>
                                    </select>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <label className="mt-3 mb-3" htmlFor='testValue'>Valor da Avaliação:</label>
                                    <input className="form-control text-center" type='tel' id='testValue' name='testValue' autoComplete='off' required onChange={this.onChangeTestValue} placeholder="Apenas números"/>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <label className="mt-3 mb-3" htmlFor='testCity'>Modelo do Laudo:</label>
                                    <select className="form-control text-center" id='laudo' name='laudo' required onChange={(event) => {this.setState({laudo:event.target.value})}} >
                                        <option value='PF'>PF</option>
                                        <option value='CR'>CR</option>
                                    </select>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <label className="mt-3 mb-3" htmlFor='testDate'>Data do Laudo:</label>
                                    <input className="form-control text-center" type='date' id='laudoDate' name='laudoDate' autoComplete='off' required onChange={(event) => {this.setState({laudoDate:event.target.value})}} />
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-8">
                                    <label className="mt-3 mb-3">Resultado da Avaliação:</label><br/>
                                    <div className="form-check custom-radio form-check-inline">
                                        <label className="mt-3 mb-3" className="form-check-label" htmlFor='apto1'>Apto Arma</label>
                                        <input className="form-check-input" type='radio' id='apto1' name='testResult' value='apto1' required onChange={(event) => {this.setState({testResult:event.target.value})}}  />
                                    </div>
                                    <div className="form-check custom-radio form-check-inline">
                                        <label className="mt-3 mb-3" className="form-check-label" htmlFor='apto2'>Apto Vigilante</label>
                                        <input className="form-check-input" type='radio' id='apto2' name='testResult' value='apto2' required onChange={(event) => {this.setState({testResult:event.target.value})}}  />
                                    </div>
                                    <div className="form-check custom-radio form-check-inline">
                                        <label className="mt-3 mb-3" className="form-check-label" htmlFor='inapto'>Inapto</label>
                                        <input className="form-check-input" type='radio' id='inapto' name='testResult' value='inapto' required onChange={(event) => {this.setState({testResult:event.target.value})}}  />
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-8">
                                    <label className="mt-3 mb-3">Endereço:</label>
                                    <input className="form-control text-center" type='text' id='address' name='address' autoComplete='off' required onChange={(event) => {this.setState({address:event.target.value})}} />
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <label className="mt-3 mb-3">Telefone:</label>
                                    <input className="form-control text-center" type='tel' id='phone' name='phone' autoComplete='off' required onChange={(event) => {this.setState({phone:event.target.value})}} />
                                </div>
                            </div>

                            <hr className="mt-3"/>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-8">
                                    <label className="mt-3 mb-3" >Pendências:</label>
                                    <ul>
                                        {listPend}
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <div className="row">
                                        <div className="col-12 mx-auto">
                                            <input className="form-control text-center" type='text' id='pendObs' name='pendObs' autoComplete='off' placeholder='Digite uma pendência' onChange={(event) => {this.setState({pendObsTemp: event.target.value})}} />
                                            <button className="mt-3 btn btn-info" onClick={(event) => {this.setState({pendObs: this.state.pendObs.concat(this.state.pendObsTemp)});alert("Lembre-se que as pendências só serão salvas no sistema após clicar em 'Atualizar Avaliação'")}}>Adicionar Pendência</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr/>

                            <div className="container mb-3">
                                <button className="btn btn-warning" onClick={this.handleAtualizarAvaliacao}>Atualizar Avaliação</button>
                            </div>

                        </div>
                    </div>
                    : null
                }
            </div>
        );
    }
}
 
export default AtualizarAvaliacao;
import React, { Component } from 'react'
// import About from './about'
import Contact from './contact'
import Units from './units'
import Hero from './hero'
import Header from './header'
import Footer from './footer'

class Home extends Component {
    render() { 
        return (
            <div>
                <Header />
                <Hero />
                {/* <About /> */}
                <Units />
                <Contact />
                <Footer />
            </div>
        );
    }
}
 
export default Home;
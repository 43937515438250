import React from 'react';
import ReactDOM from 'react-dom';
import MyApp from "./components/myapp"

// import './assets/css/styles.css';
import './assets/css/style_gp.css';

import "./assets/vendor/bootstrap/css/bootstrap.min.css"
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css"
import "./assets/vendor/boxicons/css/boxicons.min.css"


import "./assets/img/favicon.png"
import "./assets/img/apple-touch-icon.png"

// import './assets/js/main.js';

ReactDOM.render(<MyApp />, document.getElementById('root'));
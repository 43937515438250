import React, { Component } from 'react'
import logopng from '../assets/img/logos/clinica.png'

class HeaderApp extends Component {

    handleLogout = () => {
        sessionStorage.removeItem("token");
    }

    render() { 
        return (
            // 
            <header id="header" className="fixed-top" style={{background: "rgba(0, 0, 0.8)"}}>
                <div className="container d-flex align-items-center justify-content-lg-between" >
                    <h1 className="logo me-auto me-lg-0"><a href="/"><img src={logopng} alt="" className="img-fluid" /> DjaciraDantas<span>.com</span></a></h1>
                    {/* <!-- Uncomment below if you prefer to use an image logo -->
                    <!-- <a href="index.html" className="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" className="img-fluid"></a>--> */}
                    <nav id="navbar" className="navbar order-last order-lg-0">
                        <ul>
                            <li className="dropdown"><a className="nav-link scrollto">Avaliações</a> 
                                <ul>
                                    <li><a href="/RegistrarAvaliacao">Registrar Avaliação</a></li>
                                    <li><a href="/BuscarAtualizarAvaliacao">Buscar e Atualizar Avaliação</a></li>
                                    <li><a href="/Pendencias">Pendências</a></li>
                                </ul>
                            </li>
                            <li className="dropdown"><a className="nav-link scrollto">Laudos</a> 
                                <ul>
                                    <li><a href="/GerarLaudoConclusivo">Gerar Laudo Conclusivo</a></li>
                                    <li><a href="/RegistrarLaudoDescritivo">Registrar Laudo Descritivo</a></li>
                                </ul>
                            </li>
                            <li className="dropdown"><a className="nav-link scrollto">Relações</a> 
                                <ul>
                                    <li><a href="#">Avaliações para PF</a></li>
                                </ul>
                            </li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                    <a href="/" className="get-started-btn" onClick={this.handleLogout}>Deslogar</a>
                </div>
            </header>
        );
    }
}
 
export default HeaderApp;
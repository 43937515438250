import React, { Component } from 'react'
import logopng from '../assets/img/logos/clinica.png'

class Header extends Component {

    render() { 
        return (
            // style={{background: "rgba(0, 0, 0)"}}
            <header id="header" className="fixed-top" >
                <div className="container d-flex align-items-center justify-content-lg-between">
                    <h1 className="logo me-auto me-lg-0"><a href="/#hero"><img src={logopng} alt="" className="img-fluid" /> DjaciraDantas<span>.com</span></a></h1>
                    {/* <!-- Uncomment below if you prefer to use an image logo -->
                    <!-- <a href="index.html" className="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" className="img-fluid"></a>--> */}
                    <nav id="navbar" className="navbar order-last order-lg-0">
                        <ul>
                            <li><a className="nav-link scrollto active" href="/#hero">Início</a></li>
                            {/* <li><a className="nav-link scrollto" href="#about">Sobre</a></li> */}
                            <li><a className="nav-link scrollto" href="/#units">Unidades</a></li>
                            <li><a className="nav-link scrollto" href="/#contact">Fale Conosco</a></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                    
                    <a href="/RegistrarAvaliacao" className="get-started-btn">Login</a>
                </div>
            </header>

            // <header id="header" className="fixed-top" style={{background: "rgba(0, 0, 0)"}}>
            //     <div className="container d-flex align-items-center justify-content-lg-between">
            //         <h1 className="logo me-auto me-lg-0"><a href="#page-top"><img src={logopng} alt="" className="img-fluid" /> DjaciraDantas<span>.com</span></a></h1>
            //         {/* <!-- Uncomment below if you prefer to use an image logo -->
            //         <!-- <a href="index.html" className="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" className="img-fluid"></a>--> */}
            //         <nav id="navbar" className="navbar navbar-expand-lg">
            //             <ul className="navbar-nav mr-auto">
            //                 <li><a className="nav-link active" href="#page-top">Início</a></li>
            //                 {/* <li><a className="nav-link" href="#about">Sobre</a></li> */}
            //                 {/* <li><a className="nav-link" href="#services">Serviços</a></li> */}
            //                 <li><a className="nav-link" href="#units">Unidades</a></li>
            //                 <li><a className="nav-link" href="#contact">Fale Conosco</a></li>
            //             </ul>
            //             <i className="bi bi-list mobile-nav-toggle"></i>
            //         </nav>
            //         <a href="/" className="get-started-btn">Área Restrita</a>
            //     </div>
            // </header>
        );
    }
}
 
export default Header;
import React, { Component } from 'react';
import axios from "axios";
import HeaderApp from "./headerApp"

const validarCpf = require('validar-cpf');

async function postTest(newTest) {
    return axios.post('https://clinica-djacira.herokuapp.com/api/testreg', newTest)
}

class RegistrarAvaliacao extends Component {
    state={
        _id:'',
        name:'',
        age:'',
        cpf:'',
        gender:'',
        status:'',
        education:'',
        profession:'',
        testDate: Date(),
        clientType:'',
        testValue:'',
        testCity:'',
        testResult:'',
        laudo:'',
        endereco:'',
        telefone:''
    }
    
    handleRegisterTest = async (event) => {
        event.preventDefault();

        let cpfString = this.state.cpf;
        if(!validarCpf(cpfString)){
            alert("CPF não válido.")
            return
        }

        let dateSelected = this.state.testDate;
        let id_avaliacao = this.setID(dateSelected)
        if(id_avaliacao === false){
            alert("Erro ao criar ID.")
            return
        }

        const newTest = {
            _id:id_avaliacao,
            name:this.state.name,
            age:this.state.age,
            cpf:this.state.cpf,
            gender:this.state.gender,
            status:this.state.status,
            education:this.state.education,
            profession:this.state.profession,
            testDate:this.state.testDate,
            clientType:this.state.clientType,
            testValue:this.state.testValue,
            testCity:this.state.testCity,
            testResult:this.state.testResult,
            laudo:this.state.laudo,
            endereco:this.state.endereco,
            telefone:this.state.telefone
        }
        
        console.log(newTest)

        let message = await postTest(newTest);

        console.log(message)

        if(message.data.message==="Avaliação registrada com sucesso."){
            alert("Avaliação registrada com sucesso.");
            this.setState({
                _id:'',
                name:'',
                age:'',
                cpf:'',
                gender:'',
                status:'',
                education:'',
                profession:'',
                testDate: Date(),
                clientType:'',
                testValue:'',
                testCity:'',
                testResult:'',
                laudo:'',
                endereco:'',
                telefone:''
            });
            // document.getElementById("form-reg").reset();
            window.location.reload()
        }else{
            alert("Erro ao registrar avaliação.")
        }

    }

    setID = (dateSelected) => {
        let day = dateSelected.split("-")[2];
        let month = dateSelected.split("-")[1];
        let year = dateSelected.split("-")[0];
        let cpf = this.state.cpf;

        if(day.toString().length===1){
            day = "0" +  day.toString();
        };
        if(month.toString().length===1){
            month = "0" + month.toString();
        };

        const id_avaliacao = cpf + day + month + year;
        if(id_avaliacao.length !== 19){
            return false
        }

        this.setState({_id:id_avaliacao});

        return id_avaliacao
    }

    onChangeClientType = (event) => {
        this.setState({clientType:event.target.value});
        const clientType = event.target.value

        if(!clientType.includes("Particular")){
            this.setState({testValue:"130"})
            document.getElementById("testValue").value = "130"
        }else{
            this.setState({testValue:""})
            document.getElementById("testValue").value = ""
        }
    }

    onChangeTestValue = (event) => {
        this.setState({testValue:event.target.value});
        const testValue = event.target.value

        if(isNaN(testValue) || testValue.includes(".")){
            alert("No valor da avaliação é permitido apenas números. Não digite vírgula nem centavos e nem texto. Por exemplo, se a consulta foi R$ 130,00, digite apenas 130. Se a consulta foi gratuita, digite apenas 0.")
            document.getElementById("testValue").value = ""
        }
    }

    render() { 
        
        return (
            <div>
                <HeaderApp />

                <form id="form-reg" onSubmit={this.handleRegisterTest} style={{paddingTop: 90}}>
                    <div className="form-group container text-center" style={{"width":"100%"}}>
                        <div className="row mt-3">
                            <h1>Registrar Avaliação</h1>
                        </div>

                        <hr/>
                        
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='name'>Nome Completo:</label>
                                <input className="form-control text-center" type='text' name='name' required onChange={(event) => {this.setState({name:event.target.value})}} autoComplete='off' placeholder="Iniciais em maiúsculo"/>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-3 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='gender'>Sexo:</label>
                                <select className="form-control text-center" id='gender' name='gender' required onChange={(event) => {this.setState({gender:event.target.value})}} >
                                    <option hidden value=''>Selecionar</option>
                                    <option value='Masculino'>Masculino</option>
                                    <option value='Feminino'>Feminino</option>
                                </select>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-3 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='status'>Estado Civil:</label>
                                <select className="form-control text-center" id='status' name='status' required onChange={(event) => {this.setState({status:event.target.value})}}  >
                                    <option hidden value=''>Selecionar</option>
                                    <option value='Solteiro(a)'>Solteiro(a)</option>
                                    <option value='União Estável'>União Estável</option>
                                    <option value='Separado(a)'>Separado(a)</option>
                                    <option value='Casado(a)'>Casado(a)</option>
                                    <option value='Divorciado(a)'>Divorciado(a)</option>
                                    <option value='Viúvo(a)'>Viúvo(a)</option>
                                </select>
                            </div>
                            
                            <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='education'>Escolaridade:</label>
                                <select className="form-control text-center" id='education' name='education' required onChange={(event) => {this.setState({education:event.target.value})}} >
                                    <option hidden value=''>Selecionar</option>
                                    <option value='Fundamental Incompleto'>Fundamental Incompleto</option>
                                    <option value='Fundamental Completo'>Fundamental Completo</option>
                                    <option value='Médio Incompleto'>Médio Incompleto</option>
                                    <option value='Médio Completo'>Médio Completo</option>
                                    <option value='Técnico Incompleto'>Técnico Incompleto</option>
                                    <option value='Técnico Completo'>Técnico Completo</option>
                                    <option value='Superior Incompleto'>Superior Incompleto</option>
                                    <option value='Superior Completo'>Superior Completo</option>
                                    <option value='Pós-graduação Incompleta'>Pós-graduação Incompleta</option>
                                    <option value='Pós-graduação Completa'>Pós-graduação Completa</option>
                                </select>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='age'>Idade:</label>
                                <input className="form-control text-center" type='tel' name='age' required onChange={(event) => {this.setState({age:event.target.value})}} autoComplete='off' placeholder="Apenas números"/>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='cpf'>CPF:</label>
                                <input className="form-control text-center" type='tel' name='cpf' required onChange={(event) => {this.setState({cpf:event.target.value})}} autoComplete='off' placeholder="Apenas números"/>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='profession'>Profissão:</label>
                                <input className="form-control text-center" type='text' id='profession' name='profession' required onChange={(event) => {this.setState({profession:event.target.value})}} autoComplete='off' placeholder="Iniciais em maiúsculo"/>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='clientType'>Tipo de Cliente:</label>
                                <select className="form-control text-center" id='clientType' name='clientType' required onChange={this.onChangeClientType} >
                                    <option hidden value=''>Selecionar</option>
                                    <option value='Particular - Arma'>Particular - Arma</option>
                                    <option value='Particular - Vigilante'>Particular - Vigilante</option>
                                    <option value='ADS'>ADS</option>
                                    <option value='AVLIS'>AVLIS</option>
                                    <option value='BRASIFORT'>BRASIFORT</option>
                                    <option value='CERTA'>CERTA</option>
                                    <option value={"CHAGA & ROCHA"}>{"CHAGA & ROCHA"}</option>
                                    <option value='DMB'>DMB</option>
                                    <option value='EMPRESSERV'>EMPRESSERV</option>
                                    <option value='EMVIPOL'>EMVIPOL</option>
                                    <option value='FEROLI'>FEROLI</option>
                                    <option value='FLASH'>FLASH</option>
                                    <option value='FORÇA ALERTA'>FORÇA ALERTA</option>
                                    <option value='GUARDA MUNICIPAL DE NATAL'>GUARDA MUNICIPAL DE NATAL</option>
                                    <option value='INTERFORT'>INTERFORT</option>
                                    <option value='ITAPETINGA'>ITAPETINGA</option>
                                    <option value='KAIRÓS'>KAIRÓS</option>
                                    <option value='MARSEG'>MARSEG</option>
                                    <option value='MASTER SEGURANCA'>MASTER SEGURANCA</option>
                                    <option value='MATA FRESCA'>MATA FRESCA</option>
                                    <option value='MEGAFORTES'>MEGAFORTES</option>
                                    <option value='MONTEFORTE'>MONTEFORTE</option>
                                    <option value='NATAL VIGILÂNCIA'>NATAL VIGILÂNCIA</option>
                                    <option value='NEUTRON'>NEUTRON</option>
                                    <option value='NE SEGURANCA'>NE SEGURANCA</option>
                                    <option value='PROSEGUR'>PROSEGUR</option>
                                    <option value='PROTEG'>PROTEG</option>
                                    <option value='RN SEGURANCA'>RN SEGURANCA</option>
                                    <option value='ROLAND'>ROLAND</option>
                                    <option value='SEGURPRO'>SEGURPRO</option>
                                    <option value='SERVIMOVEL'>SERVIMOVEL</option>
                                    <option value='SUPREMAX'>SUPREMAX</option>
                                    <option value='TKS SEGURANÇA'>TKS SEGURANÇA</option>
                                    <option value='USIBRAS'>USIBRAS</option>
                                    <option value='VITAMAIS'>VITAMAIS</option>
                                    <option value='--- OUTRA EMPRESA ---'>--- OUTRA EMPRESA ---</option>
                                </select>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='testDate'>Data da Avaliação:</label>
                                <input className="form-control text-center" type='date' id='testDate' name='testDate' autoComplete='off' required onChange={(event) => {this.setState({testDate:event.target.value})}} />
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='testCity'>Cidade da Avaliação:</label>
                                <select className="form-control text-center" id='testCity' name='testCity' required onChange={(event) => {this.setState({testCity:event.target.value})}} >
                                    <option hidden value=''>Selecionar</option>
                                    <option value='Parnamirim'>Parnamirim</option>
                                    <option value='Mossoró'>Mossoró</option>
                                    <option value='Pau dos Ferros'>Pau dos Ferros</option>
                                </select>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='testValue'>Valor da Avaliação:</label>
                                <input className="form-control text-center" type='tel' id='testValue' name='testValue' autoComplete='off' required onChange={this.onChangeTestValue} placeholder="Apenas números"/>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='laudo'>Modelo do Laudo:</label>
                                <select className="form-control text-center" id='laudo' name='laudo' required onChange={(event) => {this.setState({laudo:event.target.value})}} >
                                    <option hidden value=''>Selecionar</option>
                                    <option value='PF'>PF</option>
                                    <option value='CR'>CR</option>
                                </select>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-4 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}}>Resultado da Avaliação:</label><br/>
                                <div className="form-check custom-radio form-check-inline">
                                    <label className="mb-3 mt-3" className="form-check-label" htmlFor='apto1'>Apto Arma</label>
                                    <input className="form-check-input" type='radio' id='apto1' name='testResult' value='apto1' required onChange={(event) => {this.setState({testResult:event.target.value})}}  />
                                </div>
                                <div className="form-check custom-radio form-check-inline">
                                    <label className="mb-3 mt-3" className="form-check-label" htmlFor='apto2'>Apto Vigilante</label>
                                    <input className="form-check-input" type='radio' id='apto2' name='testResult' value='apto2' required onChange={(event) => {this.setState({testResult:event.target.value})}}  />
                                </div>
                                <div className="form-check custom-radio form-check-inline">
                                    <label className="mb-3 mt-3" className="form-check-label" htmlFor='inapto'>Inapto</label>
                                    <input className="form-check-input" type='radio' id='inapto' name='testResult' value='inapto' required onChange={(event) => {this.setState({testResult:event.target.value})}}  />
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='endereco'>Endereço (Opcional):</label>
                                <input className="form-control text-center" type='text' id='endereco' name='endereco' autoComplete='off' placeholder='Iniciais em maiúsculo' onChange={(event) => {this.setState({endereco:event.target.value})}} />
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-2 mx-auto">
                                <label className="mb-3 mt-3" style={{fontWeight:'bold'}} htmlFor='telefone'>Telefone (Opcional):</label>
                                <input className="form-control text-center" type='text' id='telefone' name='telefone' autoComplete='off' placeholder='Apenas números' onChange={(event) => {this.setState({telefone:event.target.value})}} />
                            </div>
                        </div>

                        <hr className="mt-3 mb-3" />

                        <div className="row">
                            <div className="col-12 mx-auto">
                                <input className="mb-3 btn btn-warning" type='submit' value='Confirmar Registro'/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
 
export default RegistrarAvaliacao;
import React, { Component } from 'react';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import axios from "axios";
import HeaderApp from "./headerApp"

const validarCpf = require('validar-cpf');

async function laudoRequest(newRequest) {
    // https://clinica-djacira.herokuapp.com/
    return axios.post('https://clinica-djacira.herokuapp.com/api/laudorequest', newRequest)
}

async function citiesRequest() {
    // https://clinica-djacira.herokuapp.com/
    return axios.get('https://clinica-djacira.herokuapp.com/api/citiesrequest')
}

async function updateLaudoDate(newLaudoDate) {
    // https://clinica-djacira.herokuapp.com/
    return axios.post('https://clinica-djacira.herokuapp.com/api/updatelaudodate', newLaudoDate)
}

class GerarLaudo extends Component {
    state={
        CPF: "",
        testDateFrom: new Date(),
        cidade: "",
        GerarLaudoPor: "cpf",
        disabledCPF: false,
        disabledDatas: true,
        arrPFData: [],
        arrCRData: []
    }

    dynamicSort = (property) => {
        var sortOrder = 1;

        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a,b) {
            if(sortOrder == -1){
                return b[property].localeCompare(a[property]);
            }else{
                return a[property].localeCompare(b[property]);
            }        
        }
    }
    
    handleSubmit = async (event) => {
        event.preventDefault();

        let newRequest = ""
        if(this.state.GerarLaudoPor === "cpf"){
            if(!validarCpf(this.state.CPF)){
                alert("CPF não válido.")
                return
            }
            newRequest = 
            {
                LaudoPor: "cpf",
                CPF: this.state.CPF
            }
        }else if(this.state.GerarLaudoPor === "datas"){
            newRequest = 
            {
                LaudoPor: "datas",
                DateFrom: this.state.testDateFrom,
                Cidade: this.state.cidade
            }
        }

        let foundTests = await laudoRequest(newRequest);
        let foundCities = await citiesRequest();

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        let today_str = dd + '/' + mm + "/" + yyyy;

        for (var i = 0; i < foundTests.data.length; ++i) {
            if(foundTests.data[i].Laudo === "PF"){
                for (var j = 0; j < foundCities.data.length; ++j) {
                    if(foundTests.data[i].TestCity === foundCities.data[j].cidade){
                        if(foundTests.data[i].LaudoDate.substr(0,10) === "1900-01-01"){
                            const newLaudoDate = {
                                _id: foundTests.data[i]._id,
                                LaudoDate: yyyy + "-" + mm + "-" + dd
                            };
                            await updateLaudoDate(newLaudoDate)
                        }
                        this.setState({
                            arrPFData: this.state.arrPFData.concat(
                                {
                                    Age: foundTests.data[i].Age,
                                    Apto1: foundTests.data[i].Apto1 === "1" ? "X" : "   ",
                                    Apto2: foundTests.data[i].Apto2 === "1" ? "X" : "   ",
                                    CPF: foundTests.data[i].CPF.substr(0, 3) + "." + foundTests.data[i].CPF.substr(3, 3) + "." + foundTests.data[i].CPF.substr(6, 3) + "-" + foundTests.data[i].CPF.substr(9, 2),
                                    Education: foundTests.data[i].Education,
                                    Gender: foundTests.data[i].Gender,
                                    Inapto: foundTests.data[i].Inapto === "1" ? "X" : "   ",
                                    Name: foundTests.data[i].Name,
                                    Profession: foundTests.data[i].Profession,
                                    Status: foundTests.data[i].Status,
                                    TestDate: String(foundTests.data[i].TestDate).substr(8, 2) + "/" + String(foundTests.data[i].TestDate).substr(5, 2) + "/" + String(foundTests.data[i].TestDate).substr(0, 4),
                                    nome: foundCities.data[j].nome,
                                    endereco: foundCities.data[j].endereco,
                                    cidade: foundCities.data[j].cidade,
                                    cep: foundCities.data[j].cep,
                                    uf: foundCities.data[j].uf,
                                    Data_Laudo: foundTests.data[i].LaudoDate.substr(0,10) === "1900-01-01" ? today_str : String(foundTests.data[i].LaudoDate).substr(8, 2) + "/" + String(foundTests.data[i].LaudoDate).substr(5, 2) + "/" + String(foundTests.data[i].LaudoDate).substr(0, 4),
                                    PageBreak: '<w:p><w:r><w:br w:type="page"/></w:r></w:p>'
                                }
                            )
                        })
                        break
                    }
                }
            }else if(foundTests.data[i].Laudo === "CR"){
                for (j = 0; j < foundCities.data.length; ++j) {
                    if(foundTests.data[i].TestCity === foundCities.data[j].cidade){
                        if(foundTests.data[i].LaudoDate.substr(0,10) === "1900-01-01"){
                            const newLaudoDate = {
                                _id: foundTests.data[i]._id,
                                LaudoDate: yyyy + "-" + mm + "-" + dd
                            };
                            await updateLaudoDate(newLaudoDate)
                        }
                        this.setState({
                            arrCRData: this.state.arrCRData.concat(
                                {
                                    Age: foundTests.data[i].Age,
                                    Apto1: foundTests.data[i].Apto1 === "1" ? "X" : "   ",
                                    Apto2: foundTests.data[i].Apto2 === "1" ? "X" : "   ",
                                    CPF: foundTests.data[i].CPF.substr(0, 3) + "." + foundTests.data[i].CPF.substr(3, 3) + "." + foundTests.data[i].CPF.substr(6, 3) + "-" + foundTests.data[i].CPF.substr(9, 2),
                                    Education: foundTests.data[i].Education,
                                    Gender: foundTests.data[i].Gender,
                                    Inapto: foundTests.data[i].Inapto === "1" ? "X" : "   ",
                                    Name: foundTests.data[i].Name,
                                    Profession: foundTests.data[i].Profession,
                                    Status: foundTests.data[i].Status,
                                    TestDate: String(foundTests.data[i].TestDate).substr(8, 2) + "/" + String(foundTests.data[i].TestDate).substr(5, 2) + "/" + String(foundTests.data[i].TestDate).substr(0, 4),
                                    nome: foundCities.data[j].nome,
                                    endereco: foundCities.data[j].endereco,
                                    cidade: foundCities.data[j].cidade,
                                    cep: foundCities.data[j].cep,
                                    uf: foundCities.data[j].uf,
                                    Data_Laudo: foundTests.data[i].LaudoDate.substr(0,10) === "1900-01-01" ? today_str : String(foundTests.data[i].LaudoDate).substr(8, 2) + "/" + String(foundTests.data[i].LaudoDate).substr(5, 2) + "/" + String(foundTests.data[i].LaudoDate).substr(0, 4),
                                    PageBreak: '<w:p><w:r><w:br w:type="page"/></w:r></w:p>'
                                }
                            )
                        })
                        break
                    }
                }
            }
        }

        if(this.state.arrPFData.length > 0){
            let arrPFData = this.state.arrPFData
            arrPFData.sort(this.dynamicSort("Name"));
            arrPFData[arrPFData.length - 1].PageBreak = ''
            this.generateDocument("https://clinicadjacira.s3.us-east-2.amazonaws.com/laudo_pf_react.docx", arrPFData, "PF")
        }
        if(this.state.arrCRData.length > 0){
            let arrCRData = this.state.arrCRData
            arrCRData.sort(this.dynamicSort("Name"));
            arrCRData[arrCRData.length - 1].PageBreak = ''
            this.generateDocument("https://clinicadjacira.s3.us-east-2.amazonaws.com/laudo_cr_react.docx", arrCRData, "CR")
        }
        
        if(this.state.arrCRData.length === 0 & this.state.arrPFData.length === 0){
            if(this.state.GerarLaudoPor === "datas"){
                alert("Não há nenhuma avaliação registrada nesta data e cidade.")
            }else{
                alert("Não há nenhuma avaliação registrada com esse CPF.")
            }
            return
        }

        this.setState({
            arrCRData: [],
            arrPFData: []
        })
        
        // alert("Verifique os laudos em downloads.");
    }

    loadFile = (url, callback) => {
        PizZipUtils.getBinaryContent(url, callback);
    };

    generateDocument = (url, data, modelo) => {
        this.loadFile(
          url,
          (error, content) => {
            if (error) {
              throw error;
            };
    
            var zip = new PizZip(content);
            var doc = new Docxtemplater().loadZip(zip);
    
            doc.setData({
                "laudos": data
            });
    
            try {
              // render the document (replace all occurrences of {first_name} by John, {last_name} by Doe, ...)
              doc.render();
            } catch (error) {
              console.log("error", error);
              throw error;
            }
    
            var out = doc.getZip().generate({
              type: "blob",
              mimeType:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            }); //Output the document using Data-URI
    
            saveAs(out, "Laudos_" + modelo + ".docx");
          }
        );
    };

    onChangeGerarLaudoPor = (event) => {
        this.setState({GerarLaudoPor:event.target.value});
        if(event.target.value==="cpf"){
            document.getElementById("testDateFrom").value = '';
            document.getElementById("cidade").value = '';
            this.setState({
                testDateFrom: new Date(),
                disabledCPF: false,
                disabledDatas: true
            })
        }else if(event.target.value==="datas"){
            document.getElementById("porcpfinput").value = '';
            this.setState({
                CPF: "",
                disabledCPF: true,
                disabledDatas: false
            })
        }
    }

    onChangeCPF = (event) => {
        this.setState({CPF:event.target.value.replace(/\D/g,'')});
    }

    render() { 
        return (
            <div>
                <HeaderApp />
                <form onSubmit={this.handleSubmit} style={{paddingTop: 90}}>
                    {/* <div className="form-group container"> */}
                    <div className="form-group container-fluid flex text-center">
                        <div className="row mt-3">
                            <h1>Gerar Laudo</h1>
                        </div>
                        <hr/>
                        <div className="row mt-3">
                            <div className="col-sm-4 mx-auto">
                                <div className="form-check custom-radio form-check-inline">
                                    <label className="form-check-label" htmlFor='porcpf'>Por CPF</label>
                                    <input className="form-check-input" type='radio' id='porcpf' name='gerarlaudopor' value='cpf' required defaultChecked onChange={this.onChangeGerarLaudoPor}  />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-sm-4 mx-auto">
                                <input className="form-control text-center" type='tel' id='porcpfinput' name='cpf' required onChange={this.onChangeCPF} autoComplete='off' placeholder="Apenas números" disabled={this.state.disabledCPF} />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-sm-4 mx-auto">
                                <div className="form-check custom-radio form-check-inline">
                                    <label className="form-check-label" htmlFor='pordatas'>Por data e cidade</label>
                                    <input className="form-check-input" type='radio' id='pordatas' name='gerarlaudopor' value='datas' required onChange={this.onChangeGerarLaudoPor}  />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-sm-4 mx-auto">
                                <input className="form-control text-center" type='date' id='testDateFrom' name='testDate' autoComplete='off' required onChange={(event) => this.setState({testDateFrom:event.target.value})} disabled={this.state.disabledDatas} />
                                <select className="form-control text-center mt-3" id='cidade' required onChange={(event) => this.setState({cidade:event.target.value})} disabled={this.state.disabledDatas} >
                                    <option hidden value=''>Selecione a cidade:</option>
                                    <option value='Parnamirim'>Parnamirim</option>
                                    <option value='Mossoró'>Mossoró</option>
                                    <option value='Pau dos Ferros'>Pau dos Ferros</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="form-group container text-center">
                                <input className="btn btn-warning" type='submit' value='Gerar Laudo'/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
 
export default GerarLaudo;
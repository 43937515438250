import { useState } from 'react';

export default function useToken() {
    
    const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    let userToken = JSON.parse(tokenString);
    return userToken?.token
    };

    const [token, setToken] = useState(getToken());
    // choose between sessionStorage (only for same window) or localStorage (keeps longer)
    const saveToken = userToken => {
        sessionStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken.token);
    };

    return {
            setToken: saveToken,
            token
    }
    
}